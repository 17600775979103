import { UserService } from '@services/utility/UserService';
import { useStore } from '@store';

export type QueryObject = Record<string, unknown>;

const extraHeaders: Record<string, string> = {};

const addOrReplaceExtraHeader = (key: string, value: string) => {
  extraHeaders[key] = value;
};

const defaultHeaders = (requestId: string): HeadersInit => ({
  Accept: 'application/json',
  'Content-Type': 'application/json;charset=utf-8',
  'X-Request-ID': requestId,
});

// determines which auth header to send on the request
export const AuthHeaders = {
  // use the access token
  DEFAULT: 'DEFAULT',

  // don't use any token
  NO: 'NO',
} as const;
export type Keys<T> = keyof T;
export type AuthHeader = Keys<typeof AuthHeaders>;

export const HTTPMethods = {
  CONNECT: 'CONNECT',
  DELETE: 'DELETE',
  GET: 'GET',
  HEAD: 'HEAD',
  OPTIONS: 'OPTIONS',
  PATCH: 'PATCH',
  POST: 'POST',
  PUT: 'PUT',
  TRACE: 'TRACE',
} as const;
export type HTTPMethod = Keys<typeof HTTPMethods>;

export type Endpoint<RESPONSE, BODY, PATH_PARAMS, QUERY_PARAMS> = {
  method: HTTPMethod;
  path: PATH_PARAMS extends void ? string : (params: PATH_PARAMS) => string;
  parser: (json: unknown) => RESPONSE;
  __typeKeep?: BODY | QUERY_PARAMS; // this property will not be used, but is required for TypeScript
};

const getAuthorizationHeader = async (auth: AuthHeader): Promise<{ Authorization?: string }> => {
  switch (auth) {
    case AuthHeaders.DEFAULT: {
      let loggedUser = useStore().user;
      if (!loggedUser) {
        await UserService.loadStoredUser();
        loggedUser = useStore().user;
      }
      if (UserService.isTokenExpired()) {
        await UserService.refreshToken();
        loggedUser = useStore().user;
      }
      return { Authorization: `Bearer ${loggedUser?.access_token}` };
    }
    case AuthHeaders.NO:
      return {};
  }
};

const fetch = async (
  endpointName: string,
  method: HTTPMethod,
  requestId: string,
  auth: AuthHeader,
  params?: QueryObject,
  signal?: AbortSignal
): Promise<Response> =>
  window.fetch(endpointName, {
    method,
    headers: {
      ...APIBasics.defaultHeaders(requestId),
      ...(await getAuthorizationHeader(auth)),
      ...extraHeaders,
    } as HeadersInit,
    body: method === HTTPMethods.GET ? undefined : JSON.stringify(params),
    signal,
  });

export const APIBasics = { defaultHeaders, fetch, addOrReplaceExtraHeader };

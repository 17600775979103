<script lang="ts" setup>
import { watch } from 'vue';
import { OnyxToast, useToast } from 'sit-onyx';
import { MessageType, ToastColors } from '@customTypes/ToastMessages';
import { Store } from '@store';
import { ErrorService } from '../../services/utility/ErrorService'
import { useMsgFormatter } from '@i18n';

const { t } = useMsgFormatter();
const toast = useToast();
const props = defineProps<{
  toastType?: MessageType;
  businessToastMessage?: string;
}>();

type UpdateToastClose = (e: 'update:onToastClose') => void;
const emit = defineEmits<UpdateToastClose>();
let toastMessage: string;

watch(
  () => Store.apiError,
  (newVal) => {
    if (newVal) {
      const status = newVal.status;
      if (status !== undefined) {
        const errorType = ErrorService.getErrorType(status);
        showToast(errorType);
      }
    }
  }
);
watch( 
  () => props.toastType, 
  (newVal) => { 
    if (newVal !== undefined) { 
      toastMessage = props.businessToastMessage || '';
      showToast(newVal);
      emit('update:onToastClose');
    } 
  },
);

const showToast = (toastType: MessageType) => {
  if (toastMessage === undefined) {
    toastMessage = Store.apiError
      ? `${Store.apiError.message}`
      : 'No error message available';

    if (Store.apiError && Store.apiError.requestId) {
      toastMessage += ` Request ID: ${Store.apiError.requestId}`;
    }
  }
  const color = ToastColors[toastType];
  toast.show({
    headline: `${toastType}`,
    description: t(toastMessage),
    color: color,
    duration: 15000, // 15 seconds
    density: 'default',
  });

  onToastClose();
};

const onToastClose = () => { 
  ErrorService.dismissError();
};

</script>

<template>
    <OnyxToast class="toast-wrapper-onyx" data-testid="OnyxToastMessage" />
</template>
<style lang="scss" scoped>
.toast-wrapper-onyx {
  position: fixed;
  left: 600px;
  right: 50px;
  bottom: 100px;
  z-index: 7;
}
</style>
